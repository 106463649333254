import { updateOrderState } from "@/api/order";

export default function setState(id:number, state: string, data: any) {
  const requestData = {
    event: state,
    data
  }

  return updateOrderState(id, requestData)
}
