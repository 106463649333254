import React from "react";
import {Routes, Route, Navigate} from "react-router-dom";

import "./App.less";
import useAuth, {AuthProvider} from "@/hooks/useAuth";
import LoginPage from "@/components/pages/LoginPage";
import Layout from "@/components/UI/Layout";
import OrderListPage from "@/components/pages/OrdersListPage";
import OrderPage from "@/components/pages/OrderPage";

export default function App() {
    return (
        <AuthProvider>
            <Routes>
                <Route element={<Layout/>}>
                    <Route path="/login" element={<LoginPage/>}/>
                    <Route
                        path="/"
                        element={
                            <RequireAuth>
                                <OrderListPage/>
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="/order/:id"
                        element={
                            <RequireAuth>
                                <OrderPage/>
                            </RequireAuth>
                        }
                    />
                </Route>
            </Routes>
        </AuthProvider>
    );
}

function RequireAuth({children}: { children: JSX.Element }) {
    const auth = useAuth();

    if (!auth.isAuth && !auth.isLoading) {
        return <Navigate to="/login" replace/>;
    }

    return children;
}
