import {List, Tag, Typography} from "antd";
import {useNavigate} from "react-router-dom";
import {useRequest} from "ahooks";

import {getOrdersList} from "@/api/order";
import Loader from "@/components/UI/Loader";

const {Text} = Typography;

export default function OrderListPage() {
    const navigate = useNavigate();

    const { data, loading } = useRequest(getOrdersList);

    function handleOpen(id: number) {
        navigate(`/order/${id}`, {state: {title: `Заявка #${id}`}});
    }

    return (
        <div className="container container_ph24">
            {loading && <Loader/>}
            {!loading && (
                <>
                    <List
                        itemLayout="horizontal"
                        dataSource={data}
                        style={{
                            flex: 1,
                        }}
                        renderItem={(item: any) => {
                            const {id, title, state_rec} =
                                item;

                            return (
                                <List.Item
                                    className="order pointer"
                                    onClick={() => handleOpen(id)}
                                >
                                    <div className="order__row">
                                        <Text>{title}</Text>
                                    </div>
                                    <div className="order__row">
                                        <Tag color={state_rec.style}
                                             style={{margin: 0, fontSize: 14, lineHeight: "20px"}}>
                                            {state_rec.name}
                                        </Tag>
                                    </div>
                                </List.Item>
                            );
                        }}
                    />
                </>
            )}
        </div>
    );
}
