import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {Input, Button, Divider, Form, Typography} from "antd";

import useAuth from "@/hooks/useAuth";

const {Text} = Typography;

export default function LoginPage() {
    const navigate = useNavigate();
    const auth = useAuth();
    const [error, setError] = useState<string>("");

    function handleSubmit(values: any) {
        setError("");
        auth.signin(
            values,
            () => {
                navigate("/", {replace: true});
            },
            () => {
                setError("Неверный логин или пароль");
            }
        );
    }

    return (
        <div className="container container_center">
            <div className="content">
                <h1 className="text-center app-name">ПД: Сервис</h1>
                <Form
                    name="login"
                    style={{
                        display: "flex",
                        flexDirection: "column",
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={handleSubmit}
                    autoComplete="off"
                >
                    <Form.Item
                        name="login"
                        className="mb-10"
                        rules={[
                            {
                                required: true,
                                message: "Введите номер телефона",
                            },
                        ]}
                    >
                        <Input type="tel" placeholder="Телефон" />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        className="mb-10"
                        rules={[
                            {
                                required: true,
                                message: "Введите пароль",
                            },
                        ]}
                    >
                        <Input.Password type="password" placeholder="Пароль"/>
                    </Form.Item>
                    {!!error && <Text type="danger">{error}</Text>}
                    <Button
                        type="link"
                        style={{
                            padding: 0,
                            marginBottom: 30,
                            alignSelf: "flex-end",
                        }}
                    >
                        Забыли пароль?
                    </Button>
                    <Button type="primary" htmlType="submit" block>
                        Войти
                    </Button>
                    <Divider style={{marginTop: 50, marginBottom: 50}}/>
                </Form>
            </div>
        </div>
    );
}
