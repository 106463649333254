import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Col, Row, Tag, Typography, Image, Button } from "antd";
import { useRequest, useToggle } from "ahooks";
import React, { useEffect, useState } from "react";

import { getOrderDetails } from "@/api/order";
import { getLocaleDate, setState } from "@/helpers";
import Loader from "@/components/UI/Loader";
import StateModal from "@/components/UI/StateModal";

const { Text, Link } = Typography;

export default function OrderPage() {
    const params = useParams();
    const [isModal, { setRight: setIsModalRight, setLeft: setIsModalLeft }] = useToggle();
    const [modalData, setModalData] = useState<any>({});
    const [orderState, setOrderState] = useState<string>("");
    const location = useLocation();
    const navigate = useNavigate();


    const { data, loading, run } = useRequest(() => getOrderDetails(params.id), { ready: !!params?.id });
    console.log(data)
    useEffect(() => {
        if (!location.state?.title) {
            navigate(location.pathname, { state: { title: `Заявка #${params.id}` }, replace: true });
        }
        // eslint-disable-next-line
    }, []);

    function handleOpenModal(state: string, data: any) {
        setOrderState(state);
        if (!!data?.schema) {
            setIsModalRight();
            setModalData(data);
        } else {
            handleChangeState(data);
        }
    }

    function handleChangeState(data: any) {
        if (params.id) {
            setState(+params.id, orderState, data)
                .finally(() => {
                    run();
                    setIsModalLeft();
                });
        }
    }

    function handleModalCancel() {
        setIsModalLeft();
    }


    return (
        <div className="container container_ph0 h-100">
            {loading && <Loader />}
            {!loading && (
                <>
                    <div className="flex-1 scroll ph-24 mb-10">
                        <div className="mb-16">
                            <Text type="secondary">Дата размещения</Text>
                            <Text className="block">{getLocaleDate(data.created_at)}</Text>
                        </div>
                        <div className="mb-16">
                            <Text type="secondary">Номер заявки</Text>
                            <Text className="block">{data.id}</Text>
                        </div>
                        <div className="mb-16">
                            <Text type="secondary" className="block">
                                Статус заявки
                            </Text>
                            <Tag color={data.state_rec.style} style={{ fontSize: 14, lineHeight: "20px", marginTop: 8 }}>
                                {data.state_rec.name}
                            </Tag>
                        </div>
                        <div className="mb-16">
                            <Text>{data.text}</Text>
                        </div>
                        <Row gutter={[8, 8]} align={"stretch"} className="mb-16">
                            <Image.PreviewGroup>
                                {!!data.photos?.length &&
                                    data.photos?.map((p: string, idx: number) => (
                                        <Col span={6} key={`${p}-${idx}`}>
                                            <Image
                                                src={p}
                                                className="img-cover"
                                                rootClassName="image-wrapper"
                                            />
                                        </Col>
                                    ))}
                            </Image.PreviewGroup>
                        </Row>
                        <Row>
                            <Text type="secondary" className="block">
                                Комментарий
                            </Text>
                        </Row>
                        <Row>
                            <p>{data?.comment}</p>
                        </Row>
                        <div className="mb-16">
                            <Text type="secondary">Адрес</Text>
                            <Text className="block">{data.address.value}</Text>
                        </div>
                        <div className="mb-16">
                            <Text type="secondary">Контактное лицо</Text>
                            <Text className="block">{data.customer_name.value}</Text>
                        </div>
                        <div className="mb-16">
                            <Text type="secondary">Телефон контактного лица</Text>
                            <Link href={`tel:${data.customer_phone}`} className="block">{data.customer_phone}</Link>
                        </div>

                        <div className="mb-16">
                            <Text type="secondary">Результат работы</Text>

                            <Image.PreviewGroup>
                                {!!data?.result_photos?.length &&
                                    data.result_photos?.map((p: string, idx: number) => (
                                        <Col span={6} key={`${p}-${idx}`}>
                                            <Image
                                                src={p}
                                                className="img-cover"
                                                rootClassName="image-wrapper"
                                            />
                                        </Col>
                                    ))}
                            </Image.PreviewGroup>
                        </div>
                        <div className="mb-16">
                            <Text type="secondary">Комментарий</Text>

                            <Text className="block">{data?.result_comment}</Text>
                        </div>
                    </div>



                    <div className="shrink-0 ph-24">
                        {
                            data.permitted_events_config.map((c: any, idx: number) => (
                                <Button type={idx === 0 ? "primary" : "default"}
                                    key={c.key}
                                    size={"large"}
                                    block
                                    className="order__btn"
                                    onClick={() => handleOpenModal(c.key, c.event_form)}>
                                    {c.name}
                                </Button>
                            ))
                        }
                    </div>
                </>
            )}
            <StateModal open={isModal}
                modalData={modalData}
                handleOk={handleChangeState}
                onCancel={handleModalCancel} />
        </div>
    );
}
