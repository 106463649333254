import React, {useEffect} from "react";
import {
    Outlet,
    useLocation,
    useNavigate
} from "react-router-dom";
import history from "history/browser";
import {Button} from "antd";
import {
    LeftOutlined,
    // UserOutlined
} from "@ant-design/icons";

import useAuth from "@/hooks/useAuth";
import Loader from "@/components/UI/Loader";

export default function Layout() {
    const auth = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        auth.checkAuth();
        // eslint-disable-next-line
    }, []);

    const title = location.state?.title || "Moи задачи";
    const {isLoading} = auth;
    const isOrderPage =
        location.pathname.includes("/order/");

    function handleBack() {
        if(window.history.length > 1) {
            history.back();
        } else {
            navigate("/");
        }
    }

    // function handleOpenProfile() {
    //     navigate("/profile", {state: {title: "Профиль"}});
    // }

    return (
        <>
            {!isLoading &&
                auth.isAuth &&
                location.pathname !== "/login" && (
                    <header className="header">
                        <div className="header__left">
                            {location.pathname !== "/" && (
                                <Button
                                    type="link"
                                    icon={<LeftOutlined/>}
                                    onClick={handleBack}
                                />
                            )}
                        </div>
                        <div className="header__center">
                            <div className="header__title">{title}</div>
                        </div>
                        <div className="header__right">
                        {/*    {*/}
                        {/*        location.pathname !== "/profile" && (*/}
                        {/*            <Button*/}
                        {/*                type="link"*/}
                        {/*                icon={<UserOutlined/>}*/}
                        {/*                onClick={handleOpenProfile}*/}
                        {/*            />*/}
                        {/*        )*/}
                        {/*    }*/}
                        </div>
                    </header>
                )}
            <main className={isOrderPage ? "no-scroll" : ""}>
                {isLoading && <Loader/>}
                {!isLoading && <Outlet/>}
            </main>
        </>
    );
}
